export default {
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  youtube: 'Youtube',
  'follow-instagram': 'Folge uns auf Instagram',
  'share-on': 'Teilen',
  'follow-on': 'Folgen Sie {name} auf',
}
