export default {
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  youtube: 'Youtube',
  'follow-instagram': '¡Síguenos en Instagram!',
  'share-on': 'Compartir en',
  'follow-on': 'Sigue a {name} en',
}
