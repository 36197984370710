export default {
  'site-name': 'Pixartprinting Blog',
  'site-title': 'Pixartprinting Blog',
  'load-more-article': 'Leer otros artículos',
  'next-slide': 'Siguiente',
  'prev-slide': 'Anterior',
  'next-pagination': 'Siguientes',
  'prev-pagination': 'Anteriores',
  'next-article': 'Artículo siguiente',
  'related-articles': 'Artículos relacionados',
  'back-to-top': 'Volver a',
  search: 'Search',
  'erase-research': 'Eliminar búsqueda',
  close: 'Cerrar',
}
