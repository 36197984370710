import it from '../../translation/it_IT'

export default {
  // i18nConfiguration
  i18n: {
    locales: [
      {
        code: 'it',
        iso: 'it',
      },
    ],
    defaultLocale: 'it',
    vueI18n: {
      fallbackLocale: 'it',
      messages: {
        it,
      },
    },
    pages: {
      'categories/business': {
        it: '/business',
      },
      'categories/get-inspired': {
        it: '/lasciati-ispirare',
      },
      'categories/how-to': {
        it: '/come-si-fa',
      },
      'categories/news': {
        it: '/le-novita',
      },
      'categories/printstories': {
        it: '/printstories',
      },
      'editorial-staff/index': {
        it: '/redazione',
      },
      'editorial-staff/_author': {
        it: '/redazione/:author?',
      },
    },
  },
  /* 
    Categories Configuration
    @order: [] è l'array delle chiavi delle categorie e rappresenta l'ordine con cui verranno visualizzate le categorie dove richieste
    list{
        @key è la chiave della categoria. Per convenzione chiamata come il folder dentro page.
        {
          @slug è lo slug della categoria inserito in categorie su Wordpress
          @view è la view di nuxt e corrisponde al name utilizzato nei nuxt-link
        }
    }
  */
  pages: {
    // leggere README.md
    list: [
      {
        key: 'index', // Home per comodità la trattiamo come una categoria
        wpUri: '/',
      },
      {
        key: 'business',
        wpUri: '/business/',
      },
      {
        key: 'get-inspired',
        wpUri: '/lasciati-ispirare/',
      },
      {
        key: 'how-to',
        wpUri: '/come-si-fa/',
      },
      {
        key: 'news',
        wpUri: '/le-novita/',
      },
      {
        key: 'printstories',
        wpUri: '/printstories/',
      },
      {
        key: 'editorial-staff',
        wpUri: '/redazione/',
      },
    ],
  },
  categories: {
    // leggere README.md
    list: [
      {
        key: 'business',
        wpSlug: 'business',
        view: 'categories-business',
        wpCategoryId: 4179,
      },
      {
        key: 'get-inspired',
        wpSlug: 'lasciatispirare',
        view: 'categories-get-inspired',
        wpCategoryId: 69,
      },
      {
        key: 'how-to',
        wpSlug: 'comesifa',
        view: 'categories-how-to',
        wpCategoryId: 66,
      },
      {
        key: 'news',
        wpSlug: 'lenovita',
        view: 'categories-news',
        wpCategoryId: 70,
      },
      {
        key: 'printstories',
        wpSlug: 'printstories',
        view: 'categories-printstories',
        wpCategoryId: 15466,
      },
      {
        key: 'highlights',
        wpSlug: 'highlights',
        view: null,
        wpCategoryId: 15465,
      },
      {
        key: 'business-growth',
        wpSlug: 'businessgrowth',
        view: null,
        wpCategoryId: 15464,
      },
    ],
  },
  pixartWebsite: 'https://www.pixartprinting.it/',
  navigation: {
    primary: {
      order: ['business', 'printstories', 'how-to', 'get-inspired', 'news'],
    },
    // Categorie che vengono filtrate da eventuali elenchi (es. la lista delle categorie a cui appartiene un post)
    exclude: ['highlights', 'business-growth'],
  },
  social: [
    {
      key: 'facebook',
      url: 'https://www.facebook.com/pixartprinting.it/',
    },
    {
      key: 'instagram',
      url: 'https://www.instagram.com/pixartprinting/',
    },
    {
      key: 'linkedin',
      url: 'https://www.linkedin.com/company/pixartprinting/',
    },
  ],
  terms: [
    {
      key: 'privacy-policy',
      url: 'https://www.pixartprinting.it/privacy/',
    },
  ],
  shopLinks: [
    {
      label: 'Stampa online',
      url: 'https://www.pixartprinting.it/',
    },
    {
      label: 'Biglietti da visita',
      url: 'https://www.pixartprinting.it/biglietti-da-visita/',
    },
    {
      label: 'Volantini e flyer',
      url: 'https://www.pixartprinting.it/piccolo-formato/stampa-volantini-flyer/',
    },
    {
      label: 'Etichette adesive',
      url: 'https://www.pixartprinting.it/stampa-etichette-cartellini/',
    },
    {
      label: 'Adesivi personalizzati',
      url: 'https://www.pixartprinting.it/stampa-etichette-cartellini/etichette-adesive/',
    },
    {
      label: 'Packaging',
      url: 'https://www.pixartprinting.it/packaging/',
    },
    {
      label: 'Scatole personalizzate',
      url: 'https://www.pixartprinting.it/packaging/scatole-personalizzate/',
    },
    {
      label: 'Shopper e buste',
      url: 'https://www.pixartprinting.it/shopper-buste-personalizzate/',
    },
    {
      label: 'Riviste, libri e cataloghi',
      url: 'https://www.pixartprinting.it/stampa-riviste-cataloghi-libri/',
    },
    {
      label: 'Stampa su tela',
      url: 'https://www.pixartprinting.it/stampa-foto-online/stampa-su-tela/',
    },
    {
      label: 'Gadget',
      url: 'https://www.pixartprinting.it/gadget-personalizzati/',
    },
    {
      label: 'Calendari e agende',
      url: 'https://www.pixartprinting.it/calendari/',
    },
  ],
  nuxtConfiguration: {
    head: {
      script: [
        {
          src: 'https://21fd768e7ea04855a8a850bd8ab542cf.js.ubembed.com',
          async: true,
        },
      ],
    },
  },
}
