export default {
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  youtube: 'Youtube',
  'follow-instagram': 'Siga-nos no Instagram',
  'share-on': 'Compartilhar em',
  'follow-on': 'Siga {name} em',
}
