import en from '../../translation/en_GB'

export default {
  // i18nConfiguration
  i18n: {
    locales: [
      {
        code: 'en',
        iso: 'en-US',
      },
    ],
    defaultLocale: 'en',
    vueI18n: {
      fallbackLocale: 'en',
      messages: {
        en,
      },
    },
    pages: {
      'categories/business': {
        en: '/business',
      },
      'categories/get-inspired': {
        en: '/get-inspired',
      },
      'categories/how-to': {
        en: '/how-to-guides',
      },
      'categories/news': {
        en: '/news',
      },
      'categories/printstories': {
        en: '/printstories',
      },
      'editorial-staff/index': {
        en: '/editorial-team',
      },
      'editorial-staff/_author': {
        en: '/editorial-team/:author?',
      },
    },
  },
  /* 
    Categories Configuration
    @order: [] è l'array delle chiavi delle categorie e rappresenta l'ordine con cui verranno visualizzate le categorie dove richieste
    list{
        @key è la chiave della categoria. Per convenzione chiamata come il folder dentro page.
        {
          @slug è lo slug della categoria inserito in categorie su Wordpress
          @view è la view di nuxt e corrisponde al name utilizzato nei nuxt-link
        }
    }
  */
  pages: {
    // leggere README.md
    list: [
      {
        key: 'index', // Home per comodità la trattiamo come una categoria
        wpUri: '/',
      },
      {
        key: 'business',
        wpUri: '/business/',
      },
      {
        key: 'get-inspired',
        wpUri: '/get-inspired/',
      },
      {
        key: 'how-to',
        wpUri: '/how-to-guides/',
      },
      {
        key: 'news',
        wpUri: '/news/',
      },
      {
        key: 'printstories',
        wpUri: '/printstories/',
      },
      {
        key: 'editorial-staff',
        wpUri: '/editorial-team/',
      },
    ],
  },
  categories: {
    // leggere README.md
    list: [
      {
        key: 'business',
        wpSlug: 'business',
        view: 'categories-business',
        wpCategoryId: 4096,
      },
      {
        key: 'get-inspired',
        wpSlug: 'lasciatiispirare',
        view: 'categories-get-inspired',
        wpCategoryId: 69,
      },
      {
        key: 'how-to',
        wpSlug: 'comesifa',
        view: 'categories-how-to',
        wpCategoryId: 67,
      },
      {
        key: 'news',
        wpSlug: 'lenovita',
        view: 'categories-news',
        wpCategoryId: 70,
      },
      {
        key: 'printstories',
        wpSlug: 'printstories',
        view: 'categories-printstories',
        wpCategoryId: 6980,
      },
      {
        key: 'highlights',
        wpSlug: 'highlights',
        view: null,
        wpCategoryId: 6979,
      },
      {
        key: 'business-growth',
        wpSlug: 'businessgrowth',
        view: null,
        wpCategoryId: 6978,
      },
    ],
  },
  pixartWebsite: 'https://www.pixartprinting.com/',
  navigation: {
    primary: {
      // TODO: Per rivisualizzare nel menu le categorie commentate basta togliere il commento.
      order: ['business', 'printstories', 'how-to', 'get-inspired', 'news'],
    },
    // Categorie che vengono filtrate da eventuali elenchi (es. la lista delle categorie a cui appartiene un post)
    exclude: ['highlights', 'business-growth'],
  },
  social: [
    {
      key: 'facebook',
      url: 'https://www.facebook.com/pixartprinting.co.uk/',
    },
    {
      key: 'instagram',
      url: 'https://www.instagram.com/pixarteurope/',
    },
    {
      key: 'linkedin',
      url: 'https://www.linkedin.com/company/pixartprinting/',
    },
  ],
  terms: [
    {
      key: 'privacy-policy',
      url: 'https://www.pixartprinting.com/privacy/',
    },
  ],
  shopLinks: [
    {
      label: 'Online Printing Service',
      url: 'https://www.pixartprinting.com/',
    },
    {
      label: 'Business Cards',
      url: 'https://www.pixartprinting.com/business-cards/',
    },
    {
      label: 'Flyers and Leaflets',
      url: 'https://www.pixartprinting.com/digital-litho-printing/printing-leaflets-flyers/',
    },
    {
      label: 'Label Printing',
      url: 'https://www.pixartprinting.com/printing-labels-tags/',
    },
    {
      label: 'Sticker Printing',
      url: 'https://www.pixartprinting.com/printing-labels-tags/stickers/',
    },
    {
      label: 'Packaging',
      url: 'https://www.pixartprinting.com/packaging/',
    },
    {
      label: 'Custom Boxes',
      url: 'https://www.pixartprinting.com/packaging/standard-packaging/',
    },
    {
      label: 'Bag Printing',
      url: 'https://www.pixartprinting.com/printed-bag/',
    },
    {
      label: 'Magazines, Books & Catalogues',
      url: 'https://www.pixartprinting.com/printing-magazines-books-catalogues/',
    },
    {
      label: 'Canvas Prints',
      url: 'https://www.pixartprinting.com/photo-products/canvas-prints/',
    },
    {
      label: 'Promotional Items',
      url: 'https://www.pixartprinting.com/customised-promotional-items/',
    },
    {
      label: 'Calendars & Diaries',
      url: 'https://www.pixartprinting.com/personalised-calendars/',
    },
  ],
  nuxtConfiguration: {
    head: {},
  },
}
