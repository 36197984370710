import general from './general.js'
import categories from './categories.js'
import navigation from './navigation.js'
import author from './author.js'
import social from './social.js'
import footer from './footer.js'
import home from './home.js'
import login from './login.js'
import error from './error.js'

export default {
  general,
  categories,
  navigation,
  author,
  social,
  footer,
  home,
  login,
  error,
}
