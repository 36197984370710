export const state = () => ({
  params: '',
  open: false,
})

export const mutations = {
  setParams: (state, payload) => {
    state.params = payload
  },
  setOverlay: (state, payload) => {
    state.open = payload
  },
}
