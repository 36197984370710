export default {
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  youtube: 'Youtube',
  'follow-instagram': 'Suis-nous sur Instagram',
  'share-on': 'Partager sur',
  'follow-on': 'Suivez {name} sur',
}
