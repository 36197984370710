import pt from '../../translation/pt_PT'

export default {
  // i18nConfiguration
  i18n: {
    locales: [
      {
        code: 'pt',
        iso: 'pt',
      },
    ],
    defaultLocale: 'pt',
    vueI18n: {
      fallbackLocale: 'pt',
      messages: {
        pt,
      },
    },
    pages: {
      'categories/business': {
        pt: '/business',
      },
      'categories/get-inspired': {
        pt: '/inspire-se',
      },
      'categories/how-to': {
        pt: '/como-fazer',
      },
      'categories/news': {
        pt: '/noticias',
      },
      'categories/printstories': {
        pt: '/printstories',
      },
      'editorial-staff/index': {
        pt: '/redacao',
      },
      'editorial-staff/_author': {
        pt: '/redacao/:author?',
      },
    },
  },
  /* 
    Categories Configuration
    @order: [] è l'array delle chiavi delle categorie e rappresenta l'ordine con cui verranno visualizzate le categorie dove richieste
    list{
        @key è la chiave della categoria. Per convenzione chiamata come il folder dentro page.
        {
          @slug è lo slug della categoria inserito in categorie su Wordpress
          @view è la view di nuxt e corrisponde al name utilizzato nei nuxt-link
        }
    }
  */
  pages: {
    // leggere README.md
    list: [
      {
        key: 'index', // Home per comodità la trattiamo come una categoria
        wpUri: '/',
      },
      {
        key: 'business',
        wpUri: '/business/',
      },
      {
        key: 'get-inspired',
        wpUri: '/inspire-se/',
      },
      {
        key: 'how-to',
        wpUri: '/como-fazer/',
      },
      {
        key: 'news',
        wpUri: '/noticias/',
      },
      {
        key: 'printstories',
        wpUri: '/printstories/',
      },
      {
        key: 'editorial-staff',
        wpUri: '/redacao/',
      },
    ],
  },
  categories: {
    // leggere README.md
    list: [
      {
        key: 'business',
        wpSlug: 'business',
        view: 'categories-business',
        wpCategoryId: 4096,
      },
      {
        key: 'get-inspired',
        wpSlug: 'lasciatiispirare',
        view: 'categories-get-inspired',
        wpCategoryId: 69,
      },
      {
        key: 'how-to',
        wpSlug: 'comesifa',
        view: 'categories-how-to',
        wpCategoryId: 67,
      },
      {
        key: 'news',
        wpSlug: 'lenovita',
        view: 'categories-news',
        wpCategoryId: 70,
      },
      {
        key: 'printstories',
        wpSlug: 'printstories',
        view: 'categories-printstories',
        wpCategoryId: 6980,
      },
      {
        key: 'highlights',
        wpSlug: 'highlights',
        view: null,
        wpCategoryId: 6979,
      },
      {
        key: 'business-growth',
        wpSlug: 'businessgrowth',
        view: null,
        wpCategoryId: 6978,
      },
    ],
  },
  pixartWebsite: 'https://www.pixartprinting.com.pt/',
  navigation: {
    primary: {
      // TODO: Per rivisualizzare nel menu le categorie commentate basta togliere il commento.
      order: [
        'business',
        /* 'printstories', */ 'how-to',
        'get-inspired' /* 'news' */,
      ],
    },
    // Categorie che vengono filtrate da eventuali elenchi (es. la lista delle categorie a cui appartiene un post)
    exclude: ['highlights', 'business-growth'],
  },
  social: [
    {
      key: 'facebook',
      url: 'https://www.facebook.com/pixartprinting.com.pt',
    },
    {
      key: 'instagram',
      url: 'https://www.instagram.com/pixarteurope/',
    },
    {
      key: 'linkedin',
      url: 'https://www.linkedin.com/company/pixartprinting/',
    },
  ],
  terms: [
    {
      key: 'privacy-policy',
      url: 'https://www.pixartprinting.com.pt/privacy/',
    },
  ],
  shopLinks: [
    {
      label: 'Impressão online',
      url: 'https://www.pixartprinting.com.pt/',
    },
    {
      label: 'Cartões de visita',
      url: 'https://www.pixartprinting.com.pt/cartoes-de-visita/',
    },
    {
      label: 'Flyers',
      url: 'https://www.pixartprinting.com.pt/formato-pequeno/impressao-flyers/',
    },
    {
      label: 'Autocolantes',
      url: 'https://www.pixartprinting.com.pt/impressao-etiquetas-auto-colantes/',
    },
    {
      label: 'Stickers adesivos',
      url: 'https://www.pixartprinting.com.pt/impressao-etiquetas-auto-colantes/stickers-adesivos/',
    },
    {
      label: 'Embalagens',
      url: 'https://www.pixartprinting.com.pt/embalagens/',
    },
    {
      label: 'Embalagens de cartão',
      url: 'https://www.pixartprinting.com.pt/embalagens/embalagens-standard/',
    },
    {
      label: 'Sacos personalizados',
      url: 'https://www.pixartprinting.com.pt/sacos-personalizaveis/',
    },
    {
      label: 'Revistas, catálogos, livros',
      url: 'https://www.pixartprinting.com.pt/impressao-revistas-catalogos-livros/',
    },
    {
      label: 'Impressao em tela',
      url: 'https://www.pixartprinting.com.pt/fotoprodutos/foto-em-tela/',
    },
    {
      label: 'Brindes personalizados',
      url: 'https://www.pixartprinting.com.pt/brindes-personalizados/',
    },
    {
      label: 'Calendários e agendas',
      url: 'https://www.pixartprinting.com.pt/calendarios/',
    },
  ],
}
