
export default {
  layout: 'error',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  head() {
    return {
      ...this.seo,
    }
  },
  computed: {
    seo() {
      const seo = this.$generateTags(
        {
          title:
            this.error.statusCode === 404
              ? this.$t('error.not-found')
              : this.$t('error.internal-error'),
          metaDesc:
            this.error.statusCode === 404
              ? this.$t('error.not-found')
              : this.$t('error.internal-error'),
        },
        this.$i18n
      )
      return {
        ...seo,
        meta: seo.meta.concat({
          hid: 'robots:noindex',
          name: 'robots',
          content: 'noindex',
        }),
      }
    },
  },
}
