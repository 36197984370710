import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { ApolloLink } from 'apollo-link'
import { RetryLink } from 'apollo-link-retry'
import { onError } from 'apollo-link-error'
import introspectionQueryResultData from './possible-types.json'

// import { createHttpLink } from 'apollo-link-http'

const mapCookie = (cookie) =>
  cookie
    ? cookie
        .substring(1, cookie.length - 1)
        .split(';')
        .map((item) => item.split('='))
        .reduce((acc, item) => ({ ...acc, [item[0].trim()]: item[1] }), {})
    : []

const cache = new InMemoryCache({
  introspectionQueryResultData,
})

const authLink = (token) =>
  setContext((req, { headers }) => {
    const isSsrMode = typeof window === 'undefined'
    return {
      headers: {
        ...(!isSsrMode ? headers : []),
        authorization: token || '',
      },
    }
  })

/* const httpLink = createHttpLink({
  uri: process.env.NUXT_ENV_GQL_URL,
}) */

// Error handling link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
    // Handle network errors here (e.g., retry with a delay)
  }
})

// Retry link configuration
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
})

export default ({ req }) => {
  const cookie = mapCookie(
    process.server
      ? JSON.stringify(req?.headers?.cookie)
      : JSON.stringify(document.cookie)
  )

  return {
    httpEndpoint: process.env.NUXT_ENV_GQL_URL,
    getAuth: () => process.env.NUXT_ENV_GQL_KEY,
    cache,
    // https://www.apollographql.com/docs/react/networking/authentication/#header
    link: ApolloLink.from([
      authLink(decodeURI(cookie['apollo-token'])),
      errorLink,
      retryLink,
      // httpLink
    ]),
  }
}
