export default {
  'site-name': 'Pixartprinting Blog',
  'site-title': 'Pixartprinting Blog',
  'load-more-article': 'Lees meer artikelen',
  'next-slide': 'Volgende',
  'prev-slide': 'Vorige',
  'next-pagination': 'Volgende',
  'prev-pagination': 'Vorige',
  'next-article': 'Volgend artikel',
  'related-articles': 'Gerelateerde artikelen',
  'back-to-top': 'Terug naar boven',
  search: 'Zoek',
  'erase-research': 'Verwijder zoekopdracht',
  close: 'Sluit',
}
