import fr from '../../translation/fr_FR'

export default {
  // i18nConfiguration
  i18n: {
    locales: [
      {
        code: 'fr',
        iso: 'be-FR',
      },
    ],
    defaultLocale: 'fr',
    vueI18n: {
      fallbackLocale: 'fr',
      messages: {
        fr,
      },
    },
    pages: {
      'categories/business': {
        fr: '/business',
      },
      'categories/get-inspired': {
        fr: '/trouvez-linspiration',
      },
      'categories/how-to': {
        fr: '/tutoriels',
      },
      'categories/news': {
        fr: '/les-nouveautes',
      },
      'categories/printstories': {
        fr: '/printstories',
      },
      'editorial-staff/index': {
        fr: '/redaction',
      },
      'editorial-staff/_author': {
        fr: '/redaction/:author?',
      },
    },
  },
  /* 
    Categories Configuration
    @order: [] è l'array delle chiavi delle categorie e rappresenta l'ordine con cui verranno visualizzate le categorie dove richieste
    list{
        @key è la chiave della categoria. Per convenzione chiamata come il folder dentro page.
        {
          @slug è lo slug della categoria inserito in categorie su Wordpress
          @view è la view di nuxt e corrisponde al name utilizzato nei nuxt-link
        }
    }
  */
  pages: {
    // leggere README.md
    list: [
      {
        key: 'index', // Home per comodità la trattiamo come una categoria
        wpUri: '/',
      },
      {
        key: 'business',
        wpUri: '/business/',
      },
      {
        key: 'get-inspired',
        wpUri: '/trouvez-linspiration/',
      },
      {
        key: 'how-to',
        wpUri: '/tutoriels/',
      },
      {
        key: 'news',
        wpUri: '/les-nouveautes/',
      },
      {
        key: 'printstories',
        wpUri: '/printstories/',
      },
      {
        key: 'editorial-staff',
        wpUri: '/redaction/',
      },
    ],
  },
  categories: {
    // leggere README.md
    list: [
      {
        key: 'business',
        wpSlug: 'business',
        view: 'categories-business',
        wpCategoryId: 4096,
      },
      {
        key: 'get-inspired',
        wpSlug: 'lasciatiispirare',
        view: 'categories-get-inspired',
        wpCategoryId: 69,
      },
      {
        key: 'how-to',
        wpSlug: 'comesifa',
        view: 'categories-how-to',
        wpCategoryId: 67,
      },
      {
        key: 'news',
        wpSlug: 'lenovita',
        view: 'categories-news',
        wpCategoryId: 70,
      },
      {
        key: 'printstories',
        wpSlug: 'printstories',
        view: 'categories-printstories',
        wpCategoryId: 6980,
      },
      {
        key: 'highlights',
        wpSlug: 'highlights',
        view: null,
        wpCategoryId: 6979,
      },
      {
        key: 'business-growth',
        wpSlug: 'businessgrowth',
        view: null,
        wpCategoryId: 6978,
      },
    ],
  },
  pixartWebsite: 'https://fr.pixartprinting.be/',
  navigation: {
    primary: {
      // TODO: Per rivisualizzare nel menu le categorie commentate basta togliere il commento.
      order: ['business', 'printstories', 'how-to', 'get-inspired', 'news'],
    },
    // Categorie che vengono filtrate da eventuali elenchi (es. la lista delle categorie a cui appartiene un post)
    exclude: ['highlights', 'business-growth'],
  },
  social: [
    {
      key: 'facebook',
      url: 'https://www.facebook.com/pixartprinting.be.fr/',
    },
    {
      key: 'instagram',
      url: 'https://www.instagram.com/pixarteurope/',
    },
    {
      key: 'linkedin',
      url: 'https://www.linkedin.com/company/pixartprinting/',
    },
  ],
  terms: [
    {
      key: 'privacy-policy',
      url: 'https://fr.pixartprinting.be/protection-des-donnees-personnels/',
    },
  ],
  shopLinks: [
    {
      label: 'Impression en ligne',
      url: 'https://fr.pixartprinting.be/',
    },
    {
      label: 'Cartes de visite',
      url: 'https://fr.pixartprinting.be/cartes-de-visite/',
    },
    {
      label: 'Flyers et tracts',
      url: 'https://fr.pixartprinting.be/petit-format/impression-flyers-tractes/',
    },
    {
      label: 'Étiquettes autocollantes',
      url: 'https://fr.pixartprinting.be/impression-etiquettes/',
    },
    {
      label: 'Autocollants',
      url: 'https://fr.pixartprinting.be/impression-etiquettes/etiquettes-adhesives/',
    },
    {
      label: 'Packaging',
      url: 'https://fr.pixartprinting.be/packaging/',
    },
    {
      label: 'Boîtes personnalisées',
      url: 'https://fr.pixartprinting.be/packaging/boite-personnalisee/',
    },
    {
      label: 'Sacs personnalisés',
      url: 'https://fr.pixartprinting.be/sac-personnalise/',
    },
    {
      label: 'Revues, livres, catalogues',
      url: 'https://fr.pixartprinting.be/impression-revues-catalogues-livres/',
    },
    {
      label: 'Impression sur toile',
      url: 'https://fr.pixartprinting.be/impression-photo/impression-sur-toile/',
    },
    {
      label: 'Goodies personnalisés',
      url: 'https://fr.pixartprinting.be/gadgets-personnalises/',
    },
    {
      label: 'Calendriers et agendas',
      url: 'https://fr.pixartprinting.be/calendriers/',
    },
  ],
  nuxtConfiguration: {
    head: {},
  },
}
