import gql from 'graphql-tag'

export const mutationLogin = gql`
  mutation MutationLogin($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      authToken
      refreshToken
      user {
        id
        name
      }
    }
  }
`
export const mutationRefreshToken = gql`
  mutation MutationRefreshToken($jwtRefreshToken: String!) {
    refreshJwtAuthToken(input: { jwtRefreshToken: $jwtRefreshToken }) {
      authToken
    }
  }
`
