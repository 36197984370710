export const state = () => ({
  sizes: {
    width: 0,
    height: 0,
  },
  device: {
    event: undefined,
  },
})

export const mutations = {
  setDevice: (state, payload) => {
    state.device = Object.assign({}, state.device, payload)
  },
  setSizes: (state, payload) => {
    state.sizes = Object.assign({}, state.sizes, payload)
  },
}
