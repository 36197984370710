export default {
  'site-name': 'Pixartprinting Blogg',
  'site-title': 'Pixartprinting Blogg',
  'load-more-article': 'Läs fler artiklar',
  'next-slide': 'Nästa',
  'prev-slide': 'Föregående',
  'next-pagination': 'Nästa',
  'prev-pagination': 'Föregående',
  'next-article': 'Nästa artikel',
  'related-articles': 'Relaterade artiklar',
  'back-to-top': 'Tillbaka till toppen',
  search: 'Sök',
  'erase-research': 'Radera sökning',
  close: 'Stäng',
}
