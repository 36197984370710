export default {
  'site-name': 'Pixartprinting Blog',
  'site-title': 'Pixartprinting Blog',
  'load-more-article': 'Read more articles',
  'next-slide': 'Next',
  'prev-slide': 'Previous',
  'next-pagination': 'Next',
  'prev-pagination': 'Previous',
  'next-article': 'Next',
  'related-articles': 'Related articles',
  'back-to-top': 'Back to top',
  search: 'Search',
  'erase-research': 'Clear search',
  close: 'Close',
}
