const setSizes = (context) => {
  document.documentElement.style.setProperty(
    '--viewport-width',
    window.innerWidth + 'px'
  )
  document.documentElement.style.setProperty(
    '--viewport-height',
    window.innerHeight + 'px'
  )
  if (typeof context.$store._mutations['app/setSizes'] !== 'undefined') {
    context.$store.commit('app/setSizes', {
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }
}

const install = (Vue) => {
  Vue.directive('viewportSizes', {
    bind(el, binding, vnode) {
      setSizes(vnode.context)
      window.addEventListener(
        'resize',
        (e) => {
          setSizes(vnode.context)
        },
        { passive: true }
      )
    },
  })
}

export default install

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install)
  if (install.installed) {
    install.installed = false
  }
}
