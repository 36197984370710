// https://github.com/TagCommander/vue-tag-commander/blob/master/nuxt.configuration.md
import TCWrapper from 'vue-tag-commander'

export default function ({ route }) {
  // on client side only
  if (process.client) {
    // instanciate the wrapper
    const wrapper = TCWrapper.getInstance()
    wrapper.reloadAllContainers()
  }
}
