export default {
  'site-name': 'Pixartprinting Blog',
  'site-title': 'Pixartprinting Blog',
  'load-more-article': 'Leggi altri articoli',
  'next-slide': 'Successivo',
  'prev-slide': 'Precedente',
  'next-pagination': 'Successivi',
  'prev-pagination': 'Precedenti',
  'next-article': 'Articolo successivo',
  'related-articles': 'Articoli correlati',
  'back-to-top': 'Torna su',
  search: 'Cerca',
  'erase-research': 'Cancella ricerca',
  close: 'Chiudi',
}
