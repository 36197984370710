export default {
  'site-name': 'Blog Pixartprinting',
  'site-title': 'Blog Pixartprinting',
  'load-more-article': 'Leia mais artigos',
  'next-slide': 'Próximo',
  'prev-slide': 'Anterior',
  'next-pagination': 'Próximos',
  'prev-pagination': 'Anteriores',
  'next-article': 'Próximo artigo',
  'related-articles': 'Artigos relacionados',
  'back-to-top': 'Voltar ao topo',
  search: 'Pesquisa',
  'erase-research': 'Apagar pesquisa',
  close: 'Fechar',
}
