const detectTouch =
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0

const install = (Vue) => {
  Vue.directive('detectHoverTouch', {
    bind(el, binding, vnode) {
      const event = detectTouch ? 'touch-device' : 'pointer-device'
      document.documentElement.classList.add(event)
      if (
        typeof vnode.context.$store._mutations['app/setDevice'] !== 'undefined'
      ) {
        vnode.context.$store.commit('app/setDevice', { event })
      }
    },
  })
}

export default install

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install)
  if (install.installed) {
    install.installed = false
  }
}
