const countWords = (str) => {
  str = str.replace(/(^\s*)|(\s*$)/gi, '')
  str = str.replace(/[ ]{2,}/gi, ' ')
  str = str.replace(/\n /, '\n')
  return str.split(' ').length
}

// Dagli studi per leggere circa 200 parole si sta circa 1 minuto
const readingTime = (content) => {
  const text = new DOMParser().parseFromString(content, 'text/html')
    .documentElement.textContent
  const time = Math.floor(countWords(text) / 200)
  return time
}

const install = (Vue) => {
  Vue.prototype.$readingTime = (content) => readingTime(content)
}

export default install

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install)
  if (install.installed) {
    install.installed = false
  }
}
