export default {
  'site-name': 'Pixartprinting Blog',
  'site-title': 'Pixartprinting Blog',
  'load-more-article': 'Weitere Artikel lesen',
  'next-slide': 'Weiter',
  'prev-slide': 'Zurück',
  'next-pagination': 'Nächste',
  'prev-pagination': 'Vorherige',
  'next-article': 'Nächster Artikel',
  'related-articles': 'Passende Produkte',
  'back-to-top': 'Nach oben',
  search: 'Search',
  'erase-research': 'Suche löschen',
  close: 'Schließen',
}
