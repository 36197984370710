export default {
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  youtube: 'Youtube',
  'follow-instagram': 'Follow us on Instagram',
  'share-on': 'Share on',
  'follow-on': 'Follow {name} on',
}
