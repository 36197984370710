export const state = () => ({
  tc_enabled: false,
})

export const mutations = {
  setTcEnabled: (state, payload) => {
    state.tc_enabled = payload
  },
}

export const getters = {
  getTcEnabled: (state) => {
    return state.tc_enabled
  },
}
