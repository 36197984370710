// https://i18n.nuxtjs.org/
import configuration from '@/configuration/blog-configuration/index.js'

const getCategoryRoute =
  (categories) =>
  (param, key = 'key') => {
    const category = categories.list.find((category) => category[key] === param)
    return {
      key: category?.key,
      slug: category?.wpSlug,
      view: category?.view,
    }
  }

const reduceCategory = (nodes, paramsList) =>
  nodes.reduce(
    (acc, item) =>
      Object.assign({}, acc, {
        [paramsList.find((params) => params.wpSlug === item.slug).key]: item,
      }),
    {}
  )

export default (context, inject) => {
  const {
    categories,
    pages,
    pixartWebsite,
    navigation,
    social,
    terms,
    shopLinks,
  } = configuration[process.env.NUXT_ENV_LOCALE]
  inject('configuration', {
    categories,
    pages,
    pixartWebsite,
    navigation,
    social,
    terms,
    shopLinks,
  })
  inject('getCategoryRoute', getCategoryRoute(categories))
  inject('reduceCategory', reduceCategory)
}
