/* eslint-disable */
import it from './config-it.js'
import de from './config-de.js'
import es from './config-es.js'
import fr from './config-fr.js'
import en from './config-en.js'
import pt from './config-pt.js'
import se from './config-se.js'
import nl from './config-nl.js'
import ie from './config-ie.js'
import be_nl from './config-be-nl.js'
import be_fr from './config-be-fr.js'
import at from './config-at.js'
import ch_de from './config-ch-de.js'
import ch_fr from './config-ch-fr.js'
import ch_it from './config-ch-it.js'
import com from './config-com.js'

export default {
  it,
  de,
  es,
  fr,
  en,
  pt,
  se,
  nl,
  ie,
  be_nl,
  be_fr,
  at,
  ch_de,
  ch_fr,
  ch_it,
  com,
}
/* eslint-enable */
