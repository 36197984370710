// https://github.com/TagCommander/vue-tag-commander/blob/master/nuxt.configuration.md
import Vue from 'vue'
import TCWrapper from 'vue-tag-commander'

const wrapper = TCWrapper.getInstance()

// Set debug for development purpose if needed
// wrapper.setDebug(true)

export default ({ app }) => {
  // Make the wrapper available in app context
  app.$wrapper = wrapper
}
// Make the wrapper available in Vue
Vue.prototype.$wrapper = wrapper
