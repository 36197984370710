export default {
  'site-name': 'Pixartprinting Blog',
  'site-title': 'Pixartprinting Blog',
  'load-more-article': "Lire d'autres articles",
  'next-slide': 'Suivant',
  'prev-slide': 'Précédent',
  'next-pagination': 'Suivants',
  'prev-pagination': 'Précédents',
  'next-article': 'Article suivant',
  'related-articles': "D'autres produits pour votre créativité",
  'back-to-top': 'Revenir en haut',
  search: 'Cerca',
  'erase-research': 'Effacer la recherche',
  close: 'Fermer',
}
