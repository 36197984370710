import { mutationRefreshToken } from '@/graphql/wordpress/mutations/login.js'

export default async ({ app, route, redirect }) => {
  if (
    app.$apolloHelpers.getToken('apollo-token') &&
    !route?.name?.includes('login')
  ) {
    try {
      const refreshToken = app.$cookies.get('token-refresh')
      const user = await app.apolloProvider.defaultClient
        .mutate({
          mutation: mutationRefreshToken,
          variables: {
            jwtRefreshToken: refreshToken,
          },
        })
        .then(({ data }) => data)

      await app.$apolloHelpers.onLogin(
        `Bearer ${user.refreshJwtAuthToken.authToken}`
      )
    } catch (e) {
      console.error(e)
      return redirect(app.localePath({ name: 'login' }))
    }
  }
}
