import es from '../../translation/es_ES'

export default {
  // i18nConfiguration
  i18n: {
    locales: [
      {
        code: 'es',
        iso: 'es',
      },
    ],
    defaultLocale: 'es',
    vueI18n: {
      fallbackLocale: 'es',
      messages: {
        es,
      },
    },
    pages: {
      'categories/business': {
        es: '/business',
      },
      'categories/get-inspired': {
        es: '/dejate-inspirar',
      },
      'categories/how-to': {
        es: '/como-se-hace',
      },
      'categories/news': {
        es: '/novedades',
      },
      'categories/printstories': {
        es: '/printstories',
      },
      'editorial-staff/index': {
        es: '/redaccion',
      },
      'editorial-staff/_author': {
        es: '/redaccion/:author?',
      },
    },
  },
  /* 
    Categories Configuration
    @order: [] è l'array delle chiavi delle categorie e rappresenta l'ordine con cui verranno visualizzate le categorie dove richieste
    list{
        @key è la chiave della categoria. Per convenzione chiamata come il folder dentro page.
        {
          @slug è lo slug della categoria inserito in categorie su Wordpress
          @view è la view di nuxt e corrisponde al name utilizzato nei nuxt-link
        }
    }
  */
  pages: {
    // leggere README.md
    list: [
      {
        key: 'index', // Home per comodità la trattiamo come una categoria
        wpUri: '/',
      },
      {
        key: 'business',
        wpUri: '/business/',
      },
      {
        key: 'get-inspired',
        wpUri: '/dejate-inspirar/',
      },
      {
        key: 'how-to',
        wpUri: '/como-se-hace/',
      },
      {
        key: 'news',
        wpUri: '/novedades/',
      },
      {
        key: 'printstories',
        wpUri: '/printstories/',
      },
      {
        key: 'editorial-staff',
        wpUri: '/redaccion/',
      },
    ],
  },
  categories: {
    // leggere README.md
    list: [
      {
        key: 'business',
        wpSlug: 'business',
        view: 'categories-business',
        wpCategoryId: 4002,
      },
      {
        key: 'get-inspired',
        wpSlug: 'dejate-inspirar',
        view: 'categories-get-inspired',
        wpCategoryId: 69,
      },
      {
        key: 'how-to',
        wpSlug: 'como-se-hace',
        view: 'categories-how-to',
        wpCategoryId: 67,
      },
      {
        key: 'news',
        wpSlug: 'novedades',
        view: 'categories-news',
        wpCategoryId: 70,
      },
      {
        key: 'printstories',
        wpSlug: 'printstories',
        view: 'categories-printstories',
        wpCategoryId: 13621,
      },
      {
        key: 'highlights',
        wpSlug: 'highlights',
        view: null,
        wpCategoryId: 13620,
      },
      {
        key: 'business-growth',
        wpSlug: 'businessgrowth',
        view: null,
        wpCategoryId: 13619,
      },
    ],
  },
  pixartWebsite: 'https://www.pixartprinting.es/',
  navigation: {
    primary: {
      order: ['business', 'printstories', 'how-to', 'get-inspired', 'news'],
    },
    // Categorie che vengono filtrate da eventuali elenchi (es. la lista delle categorie a cui appartiene un post)
    exclude: ['highlights', 'business-growth'],
  },
  social: [
    {
      key: 'facebook',
      url: 'https://www.facebook.com/pixartprinting.es/',
    },
    {
      key: 'instagram',
      url: 'https://www.instagram.com/pixartprinting/',
    },
    {
      key: 'linkedin',
      url: 'https://www.linkedin.com/company/pixartprinting/',
    },
  ],
  terms: [
    {
      key: 'privacy-policy',
      url: 'https://www.pixartprinting.es/proteccion-de-datos-y-cookies/',
    },
  ],
  shopLinks: [
    {
      label: 'Imprenta online',
      url: 'https://www.pixartprinting.es/',
    },
    {
      label: 'Tarjetas de visita',
      url: 'https://www.pixartprinting.es/tarjetas-de-visita/',
    },
    {
      label: 'Folletos y flyers',
      url: 'https://www.pixartprinting.es/formato-pequeno/impresion-folletos-flyers/',
    },
    {
      label: 'Etiquetas adhesivas',
      url: 'https://www.pixartprinting.es/impresion-etiquetas/',
    },
    {
      label: 'Pegatinas personalizadas',
      url: 'https://www.pixartprinting.es/impresion-etiquetas/stickers-adhesivos/',
    },
    {
      label: 'Packaging',
      url: 'https://www.pixartprinting.es/packaging/',
    },
    {
      label: 'Cajas personalizadas',
      url: 'https://www.pixartprinting.es/packaging/cajas-personalizadas/',
    },
    {
      label: 'Bolsas personalizadas',
      url: 'https://www.pixartprinting.es/bolsas-personalizadas/',
    },
    {
      label: 'Revistas, libros y catálogos',
      url: 'https://www.pixartprinting.es/impresion-revistas-catalogos-libros/',
    },
    {
      label: 'Lienzos personalizados',
      url: 'https://www.pixartprinting.es/imprimir-fotos/fotos-en-tela/',
    },
    {
      label: 'Merchandising',
      url: 'https://www.pixartprinting.es/articulos-personalizados/',
    },
    {
      label: 'Calendarios y agendas',
      url: 'https://www.pixartprinting.es/calendarios/',
    },
  ],
  nuxtConfiguration: {
    head: {
      script: [
        {
          src: 'https://21fd768e7ea04855a8a850bd8ab542cf.js.ubembed.com',
          async: true,
        },
      ],
    },
  },
}
