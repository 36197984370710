export default {
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'LinkedIn',
  twitter: 'Twitter',
  youtube: 'YouTube',
  'follow-instagram': 'Följ oss på Instagram',
  'share-on': 'Dela på',
  'follow-on': 'Segui {name} su',
}
