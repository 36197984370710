export default {
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'LinkedIn',
  twitter: 'Twitter',
  youtube: 'YouTube',
  'follow-instagram': 'Volg ons op Instagram',
  'share-on': 'Deel op',
  'follow-on': 'Volg {name} op',
}
