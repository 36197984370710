export default {
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  youtube: 'Youtube',
  'follow-instagram': 'Seguici su Instagram',
  'share-on': 'Condividi su',
  'follow-on': 'Segui {name} su',
}
